import axios from '@/libs/axios'

class Series {
  static getCalendarSchedule(params) {
    return axios.post('admin/series/getCalendarSchedule', params)
  }

  static store(params) {
    return axios.post('admin/series/store', params)
  }

  static update(params) {
    return axios.post('admin/series/update', params)
  }

  static destroy(id) {
    return axios.post('admin/series/delete', id)
  }
}

export default Series
