import Series from '@/models/Series'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'primary',
        label: 'Series',
      },
      {
        color: 'danger',
        label: 'Holiday',
      },
    ],
    selectedCalendars: ['Series', 'Holiday'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, startDate, endDate }) {
      return Series.getCalendarSchedule({
        start_date: startDate,
        end_date: endDate,
        calendars: calendars.join(','),
      })
    },
    addEvent(ctx, { event }) {
      return Series.store({ ...event.extendedProps.formDetails })
    },
    updateEvent(ctx, { event }) {
      return Series.update({ ...event.extendedProps.formDetails })
    },
    removeEvent(ctx, { id }) {
      return Series.destroy({ id })
    },
  },
}
